import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { apiUrl, baseUrl,imageUrl,formatToColombianCurrency } from "../../config/config";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export function ProductDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const [swiper, setSwiper] = useState();
  const [uploadedimages, setuploadedimages] = useState([]);
  const [previewImage, setpreviewImage] = useState();
  const [productFields, setproductFields] = useState();

  useEffect(() => {
    productDetail();
    getProductImages();
  }, []);

  async function productDetail() {
    setLoader(true);
    try {
      const response = await apiService.get_productDetail(id);
      if (response?.status == 200) {
        setLoader(false);
        setData(response?.data);
        setproductFields(response?.data.data.resource);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        if (error?.response?.status == 403) {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  localStorage.clear()
                  window.location.href = "/";
              }
          );
      }else{
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  console.log('OK button clicked after error alert');
              }
          );
      }
      }
    }
  }
  const getProductImages = async () => {
    const response = await apiService.get_productImages(id);
    if (response?.status == 200) {
      let imgdata = [];
      {
        console.log(response.data);
        response.data.data.list.map((img, index) => {
          imgdata.push({ url: imageUrl + img.path });
        });
        console.log(imgdata);
        setuploadedimages(imgdata);
      }
    }
  };

  console.log("????????????????????", data);
  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={11}>
                <h2>{t("product_detail")}</h2>
                <Row className="product-detail">
                  <Col md={12}>
                    {productFields ? (
                      <>
                        <Row>
                          <Col md={6} className="mt-5">
                            <div className="product-image-left">
                              {uploadedimages ? (
                                <>
                                  <Swiper
                                    slidesPerView={1}
                                    onSwiper={(swiperObj) => {
                                      setSwiper(swiperObj);
                                    }}
                                    modules={[Navigation, Pagination]}
                                    pagination={{ enabled: true }}
                                  >
                                    {uploadedimages.map((i, tabIndex) => (
                                      <SwiperSlide>
                                        <img src={i.url} />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>

                                  <button
                                    className="tab-left-arrow-btn"
                                    onClick={() => swiper.slidePrev()}
                                  >
                                    <i
                                      class="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    className="tab-right-arrow-btn"
                                    onClick={() => swiper.slideNext()}
                                  >
                                    <i
                                      class="fa fa-angle-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </Col>
                          <Col
                            md={6}
                            className="mt-5 d-flex align-items-center"
                          >
                            <div className="product-detail-right w-100">
                              <h6 className="text-light">
                                {productFields?.name}
                              </h6>
                              {/* <h3 className="text-light">{productFields?.category?.name}</h3> */}
                              <div className="product-availability-order">
                                <p className="text-light">
                                {t("status")}: &nbsp;
                                  <span>
                                    {productFields?.enabled
                                      ? "Enabled"
                                      : "Disabled"}{" "}
                                  </span>
                                </p>
                                <p className="text-light">
                                {t("category")}: &nbsp;
                                  <span>{productFields?.category?.name} </span>
                                </p>
                                {/* <p className="text-light">Min Order: &nbsp;<span>{productFields?.min_order} {productFields?.units}</span></p> */}
                              </div>
                              <div className="product-availability-order">
                                <p className="text-light">
                                {t("packaging")}: &nbsp;
                                  <span>
                                    {productFields?.packaging
                                      ? productFields?.packaging
                                      : " "}
                                  </span>
                                </p>
                                <p className="text-light">
                                {t("unit")}: &nbsp;
                                  <span>{productFields?.unit?.unit} </span>
                                </p>
                                <p className="text-light">
                                {t("price_per")}: &nbsp;
                                  <span>${formatToColombianCurrency(productFields?.price)} </span>
                                </p>
                                {/* <p className="text-light">Min Order: &nbsp;<span>{productFields?.min_order} {productFields?.units}</span></p> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="product-description-area mt-3">
                          <h6>{t("product_desc")}
                            </h6>
                          <p>{productFields?.description}</p>{" "}
                        </div>
                        {productFields?.retail_tip ? (
                          <div className="product-description-area">
                            <h6>{t("retail_tip")}
                             </h6>
                            <p> {productFields?.retail_tip}</p>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className="no-dat-found-box">
                        <img
                          src={
                            require("../../assets/images/no-data.svg").default
                          }
                          alt="da"
                        />
                        <h3 className="text-center text-light">
                          No data found
                        </h3>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
